<template>
  <div class="home">
    <div class="home--header">
      <div class="home--header-logo">
        <img src="https://vip.bdsaas.com/static/img/favicon.ico" />
      </div>
      <div class="home--header-title">
        数企前端 UI 组件库 <span style="font-size: 14px">(v{{ version }})</span>
      </div>
      <div class="home--header-github" @click="handleGitbubClick">
        <img src="../images/github.svg" />
      </div>
    </div>
    <div class="home--left">
      <div class="home--left-title">开发指南</div>
      <router-link class="home--left-link" to="/">快速上手</router-link>
      <router-link class="home--left-link" to="/guide/install"
        >安装</router-link
      >
      <router-link class="home--left-link" to="/guide/backend"
        >后端 代码规范</router-link
      >
      <router-link class="home--left-link" to="/guide/fe"
        >前端 代码规范</router-link
      >
      <router-link class="home--left-link" to="/changelog"
        >更新日志
      </router-link>
      <div class="home--left-title">插件</div>
      <router-link class="home--left-link" to="/plugin/console"
        >Log 控制台打印优化</router-link
      >
      <div class="home--left-title">Basic</div>
      <router-link class="home--left-link" to="/component/icon"
        >Icon 图标</router-link
      >
      <router-link class="home--left-link" to="/component/container"
        >Container 布局容器</router-link
      >
      <router-link class="home--left-link" to="/component/draggable"
        >Draggable 拖拽排序</router-link
      >
      <router-link class="home--left-link" to="/component/tree"
        >Tree 巨树</router-link
      >
      <div class="home--left-title">Data</div>
      <router-link class="home--left-link" to="/component/page-template"
        >PageTemplate 表格容器</router-link
      >
      <router-link class="home--left-link" to="/component/pagination"
        >Pagination 分页</router-link
      >
      <div class="home--left-title">Form</div>
      <router-link class="home--left-link" to="/component/upload"
        >Upload 上传</router-link
      >
      <router-link class="home--left-link" to="/component/user-dep-role"
        >UserDepRole 组织架构</router-link
      >
      <router-link class="home--left-link" to="/component/pan"
        >Pan 云盘</router-link
      >
      <router-link class="home--left-link" to="/component/color-picker"
        >ColorPicker 颜色选择</router-link
      >
      <div class="home--left-title">Navigation</div>
      <router-link class="home--left-link" to="/component/nav-menu"
        >NavMenu 导航菜单搜索</router-link
      >
      <div class="home--left-title">Others</div>
      <router-link class="home--left-link" to="/component/drawer"
        >Drawer 抽屉</router-link
      >
      <router-link class="home--left-link" to="/component/qrcode"
        >Qrcode 二维码</router-link
      >
      <router-link class="home--left-link" to="/component/context-menu"
        >ContextMenu 右击菜单</router-link
      >
      <router-link class="home--left-link" to="/component/editor"
        >Editor 富文本</router-link
      >
    </div>
    <div class="home--content">
      <div class="home--content-page">
        <div class="home--content-view">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { version } from "@/../package.json";
export default {
  data() {
    return {
      version
    };
  },
  methods: {
    handleGitbubClick() {
      window.open("http://112.29.174.227:3000/shire/ixu-ui.git");
    }
  }
};
</script>

<style lang="scss">
.home {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.home--header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 64px;
  line-height: 64px;
  background-color: #fff;
  box-sizing: border-box;
  transition: all 0.3s ease;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
}

.home--header-logo {
  float: left;
  padding-left: 25px;
  overflow: hidden;
  cursor: pointer;

  > img {
    height: 32px;
    vertical-align: middle;
    border-style: none;
  }
}

.home--header-title {
  height: 65px;
  line-height: 65px;
  font-size: 25px;
  display: inline-block;
  padding-left: 12px;
  font-weight: 700;
}

.home--left {
  position: fixed;
  z-index: 30;
  top: 65px;
  left: 0;
  bottom: 0;
  overflow-y: auto;
  width: 250px;
  font-size: 14px;
  margin: 30px 0;
  color: #3f536e;
  background: #fff;
  border-right: 1px solid #ececec;
  &::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
    -webkit-appearance: none;
    opacity: 0 !important;
  }
}

.home--left-title {
  font-size: 12px;
  color: #999;
  line-height: 26px;
  height: 26px;
  margin: 0;
  padding: 12px 25px 4px;
  text-decoration: none;
  display: block;
  position: relative;
  transition: 0.15s ease-out;
  font-weight: 400;
}

.home--left-link {
  font-size: 14px;
  color: #333;
  line-height: 40px;
  height: 40px;
  margin: 0;
  padding: 4px 25px;
  text-decoration: none;
  display: block;
  position: relative;
  transition: 0.15s ease-out;
  font-weight: 500;

  &:hover {
    color: #559efd;
  }
}

.home--header-github {
  position: absolute;
  height: 40px;
  width: 40px;
  right: 20px;
  top: 10px;
  color: #444444;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
  }

  > img {
    height: 100%;
    width: 100%;
  }
}

.router-link-exact-active {
  color: #559efd !important;
  background-color: rgba(#559efd, 0.2) !important;
  border-right: 3px solid #559efd !important;
}

.home--content {
  height: calc(100% - 65px);
  overflow: auto;
  margin-top: 65px;
}

.home--content-page {
  padding-left: 250px;
}

.home--content-view {
  max-width: 1000px;
  padding: 30px 50px 60px 50px;
  margin: 0 auto;
}
</style>
